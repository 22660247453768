import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import Image from "../components/image";
import BreadImage from "../components/breadImage";
import SEO from "../components/seo";
import HomeSlider from "../components/HomeSlider";
import BecomeMember from "../components/become-member";
import $ from 'jquery';
import * as appConstants from '../components/app-constants';
import useFetch from "../components/hooks/useFetch";

interface Props {
    data: any;
}

export const IndexPage = (props: Props) => {
    const { data } = props;
    const [day, setDay] = useState<any>(data.allWordpressWpDpadEvents.edges[0].node.acf.title);
    const { loading, content } = useFetch(`${appConstants.getDPADNewsItems}`);
    const sortedData = content?.data?.sort((a: any, b: any) => b.id - a.id);

    const dpadNews = sortedData?.slice(0, 3).map((dpadNew: DpadNews) => {
    const newsItem = (
        <div key={dpadNew?.attributes?.News_Title} className="col-lg-4 m-b-30">
            <div className="post-item border" style={{padding: '0px 20px 20px 0', left: 0, top: 0}}>
                <div className="post-item-wrap">
                    <div className="post-image">
                        <a href={`/news/${dpadNew?.attributes?.News_Title}`}>
                            <img src={dpadNew?.attributes?.News_Hero_Image?.data.attributes.url ?? ''} alt={dpadNew?.attributes?.News_Title} style={{ position: 'static' }} />
                        </a>
                    </div>
                    <div className="post-item-description">
                        <span className="post-meta-date"><i className="fa fa-calendar-o"></i>{dpadNew?.attributes?.Dpad_News}</span>
                        <a href={`/news/${dpadNew?.attributes?.News_Title}`}><h2 style={{fontSize: '1.357143em', paddingBottom: 14}}>{dpadNew?.attributes?.News_Title}</h2></a>
                        <p className="text-justify">{dpadNew?.attributes?.News_Short_Description}</p>
                        <a href={`/news/${dpadNew?.attributes?.News_Title}`} className="item-link">Read More <i className="fa fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    );

    return (newsItem);
    });

    useEffect(() => {
        if (loading) {
            $(".loader-item").delay(900).fadeOut();
            $("#pageloader").delay(900).fadeOut("slow");
            return;
        }
    }, [loading]);

    return (
        <Layout
            headerElement={<HomeSlider />}
            scrollStepInPx={50}
            delayInMs={2}
        >
            <SEO title="Home" />
            <div id="pageloader" className="bg-grad-animation-1">
                <div className="loader-item"></div>
            </div>
            <section id="section2" className="p-t-150 p-b-150">
                <div className="container">
                    <div className="row">
                        <div className="row">
                            <div className="col-lg-6 m-b-30">
                                <div>
                                    <BreadImage />
                                </div>
                            </div>
                            <div className="col-lg-6 text-center">
                                <div className="m-b-40">
                                    <h2 className="text-medium font-pacifico text-colored dpad-text-color">What is the DPAD Coalition?</h2>
                                    <span className="lead text-justify" style={{textAlignLast: 'center'}}>The Coalition is an open group representing organizations that serve African Nova Scotian people, communities, 
                                    interests and needs. The Coalition began to organize in response to an opportunity to present to the United Nations Working Group of 
                                    Experts for People of African Descent (WGEPAD) in Halifax on Oct. 19, 2016. The group’s members recognize that the International Decade 
                                    for People of African Descent (2015-2024) provides a unique opportunity to build provincial, national and international momentum for change 
                                    that will benefit people of African descent in Nova Scotia and beyond.
                                    </span>
                                </div>
                                <a href="/about" className="btn btn-outline btn-dark" style={{backgroundColor: '#FCB616', color: '#000', borderColor: '#FCB616'}}><span>Learn More</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>   

            <section className="p-t-150 p-b-150">
            <div className="container">
                <div className="text-center m-b-60">
                    <h1 className="text-medium dpad-text-color">OUR VALUES</h1>
                    <p className="lead">We value a respectful, transparent approach to working together that honours the diversity of opinions and experiences within African Nova Scotian communities 
                    and encourages broad engagement and collaboration. We also value the integral role of faith and spirituality in African Nova Scotian communities.</p>
                </div>
                <div className="row">
                    <div className="col-lg-4 p-t-80">
                        <div className="icon-box icon-box-right effect medium border small">
                            <h3>Trust</h3>
                            <p>We have confidence in each other’s capabilities and intentions to uphold the shared interest of the Coalition.</p>
                        </div>
                        <div className="icon-box icon-box-right effect medium border small">
                            <h3>Cooperation</h3>
                            <p>We work as a team to ensure the success of the whole Coalition while respecting the autonomy of individual organizations.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 text-center p-t-100">
                        <Image />
                    </div>

                    <div className="col-lg-4 p-t-80">
                        <div className="icon-box effect medium border small">
                            <h3>Transparency</h3>
                            <p className="text-justify">Recognizing that everyone has pre-existing relationships, we undertake to be open, honest and accountable with everyone we work with, and with each other.</p>
                        </div>
                        <div className="icon-box effect medium border small">
                            <h3>Respect</h3>
                            <p>We strive to treat all people with dignity and respect.</p>
                        </div>
                    </div>
                </div>
                </div>
            </section>

            <section className="text-light p-t-150 p-b-150" style={{backgroundImage: "url(" + "https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/serviceBg.png" + ")"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="col-lg-4">
                            <div className="heading-text heading-section">
                                <h1 className="text-medium cam">Our Mission</h1>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="row">
                            <span className="lead text-justify">Recognizing that African Nova Scotians are a distinct founding people in Nova Scotia who have been a key part of the province’s culture and history since 1605, the DPAD Coalition’s mission is twofold: to build strength and health across African Nova Scotian communities, and to forge a renewed working relationship with government(s) that creates conditions for all African-descended people in Nova Scotia to thrive.<br /><br />
                                While acknowledging that much work remains to address the legacy of enslavement, segregation and generational effects of systemic anti-Black racism in Nova Scotia, we strive to call governments out of past attitudes and behaviours—doing to or for African Nova Scotian communities, instead of with—and into a meaningful engagement that respects people as agents in their own solutions. We seek a relationship where African Nova Scotians are engaged, included and listened to at all 
                                levels of policy- shaping and decision-making. We also seek to strengthen relationships amongst ourselves to equip communities and organizations to work collectively and holistically across sectors, in an accountable and mutually supportive way.
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>  

            <section className="section about-us about-us--style-2 no-padding-top" style={{marginBottom: 80, marginTop: 150}}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <h4 className="about-us__subtitle text-justify">African Nova Scotian people and communities are recognized and supported as an integral part of Nova Scotia’s past, present and future.</h4>
                        </div>
                        <div className="col-lg-6 col-xl-5 offset-xl-1">
                            <div className="about-us__text-aside pt-0"><h1 className="text-large dpad-text-color">OUR VISION</h1></div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="section about-us p-0 p-t-150 p-b-150">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-xl-5">
                            <div className="img-box"><img className="img--layout" src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/splashy.png" alt="img" />
                                <div className="img-box__img" style={{height: '33vh'}}><img className="img--bg" src="https://www.un.org/sites/un2.un.org/files/styles/banner-image-style-27-10/public/slide-banner-edit_0.jpg" alt="img" /></div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-6 offset-xl-1 ctrl-sec-1">
                            <div className="heading heading--primary"><span className="heading__pre-title">What is</span>
                                <h2 className="heading__title"><span>The UN International Decade for People of African Descent?</span></h2>
                            </div>
                            <p className="text-justify" style={{fontSize: 16}}><strong>The United Nations has a tradition of drawing attention to important global issues that demand long-term attention. On December 10, 2014, the UN General Assembly officially declared 2015–2024 the International Decade for People of African Descent for these reasons:
                            The UN has identified three main objectives for the decade: </strong></p>
                            <ul>
                                <li className="text-justify" style={{fontSize: 16, marginBottom: 24}}>Ensure the rights of people of African descent, and their full and equal participation in all aspects of society;</li>
                                <li className="text-justify" style={{fontSize: 16, marginBottom: 24}}>Promote greater knowledge of and respect for their diverse heritage, culture, and contribution to the development of societies;</li>
                                <li className="text-justify" style={{fontSize: 16, marginBottom: 24}}>Adopt and strengthen legal frameworks to eliminate racial discrimination.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>       


            <div className="call-to-action call-to-action-colored" style={{marginTop: 140}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 d-flex align-items-center">
                            <img src="https://www.un.org/sites/www.un.org/themes/bootstrap_un/images/logo-en.svg" width="350" alt="" />
                        </div>

                        <div className="col-lg-8 mt-5">
                            <h3 className="text-light text-dark mb-4">The United Nations Fellowship Program for People of African Descent</h3>
                            <p className="text-justify" style={{fontSize: 16, color: '#000'}}>The Fellowship programme for people of African descent is a three-week intensive learning opportunity for people of African descent from the diaspora, who are engaged in promoting the rights of people of African descent.<br /><br />
                                Taking place, once a year at the Office of the High Commissioner for Human Rights in Geneva, Switzerland, the program aims to strengthen participants’ skills to contribute to the protection and promotion of civil, political, economic, social and cultural rights of people of African descent in their respective countries.<br /><br />
                                The participants are equipped with the tools necessary to enhance the development of legislation, policies and programmes; to strengthen collaboration of civil society with governments; and to undertake local awareness-raising activities.<br /><br />
                                <span className="text-center d-flex justify-content-center">View the Voices of the Decade PDF below</span>
                                <span className="text-center d-flex justify-content-center">
                                    <a href="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/Voices+of+the+Decade_Online_FINAL.pdf" className="btn btn-outline btn-dark ml-4 d-flex justify-content-center" style={{backgroundColor: '#000', color: '#fff'}}><span>Voices of the Decade</span></a>
                                </span>
                                <br />
                                <span className="text-center d-flex justify-content-center">For application information visit</span>
                                <span className="text-center d-flex justify-content-center">
                                    <a href="https://www.un.org/en/observances/decade-people-african-descent/fellowship-programme" className="btn btn-outline btn-dark ml-4 d-flex justify-content-center" style={{backgroundColor: '#000', color: '#fff'}}><span>Fellowship Programme</span></a>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>



            <section>
                <div className="container p-t-150 p-b-150">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="text-medium">JOIN OUR WORKING GROUPS!</div>
                            <div className="accordion toggle fancy clean accordion-transparent">
                            <div className="ac-item">
                                <div className="ac-content">
                                    <h4><img src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/mmere-dane-small.png" width="50" /> <span className="ml-3">Community Services Working Group</span></h4>
                                        <ul style={{marginLeft: 40}}>
                                            <li className="text-justify">Racial disparity and disproportionality in the Child Welfare system – African Nova Scotians make up 2.3% of the general population but represent more than 10% of Child Welfare cases</li>
                                            <li className="text-justify">Unequal availability of social services for African Nova Scotian children, youth, and families, especially in rural communities</li>
                                        </ul>
                                        <a href="/community-service-wg" className="btn button-light p-1 ml-2 mt-2">Join Now</a>
                                    </div>
                                </div>  
                                <div className="ac-item">
                                    <div className="ac-content"><h4><img src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/aban-small.png" width="50" /> <span className="ml-3">Health Strategy Working Group</span></h4>
                                    <ul style={{marginLeft: 40}}>
                                        <li>The need for health data (empirical or quantitative) that is reflective of the ANS population</li>
                                        <li>Health system has yet to reflect culturally competent planning and practice.</li>
                                    </ul>
                                    <a href="/health-working-group" className="btn button-light p-1 ml-2 mt-2">Join Now</a>
                                    </div>
                                </div>                                                              
                                <div className="ac-item">
                                    <div className="ac-content">
                                        <h4><img src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/sepow-small.png" width="50" /> <span className="ml-3">Justice Strategy Working Group</span></h4>
                                        <ul style={{marginLeft: 40}}>
                                            <li>Access to justice</li>
                                            <li>The roots of over-policing and surveillance (racial profiling)</li>
                                        </ul>
                                        <a href="/justice-working-group" className="btn button-light p-1 ml-2 mt-2">Join Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 text-light p-40 mt-5">
                            <a href="/membership">
                                <BecomeMember />
                            </a>
                        </div>
                    </div>
                </div>
            </section>


            <section id="section-blog" className="p-t-150 p-b-100 background-grey">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3">
                            <h1 className="dpad-text-color text-medium">DPAD News</h1>
                            <h4><i>Keep up to date on all DPAD news within the community.</i></h4>
                            <a href="/dpad-news" className="btn button-light p-1 ml-2 mt-2">See All DPAD News</a>
                        </div>
                        <div className="row col-lg-9">
                        {dpadNews}
                        </div>
                    </div>
                </div>
            </section>                     
        </Layout>
        )
    }

export default IndexPage

export const pageQuery2 = graphql`
    query HomeQuery {
        allWordpressWpDpadNews(sort: {fields: date, order: DESC}, limit: 3) {
            edges {
            node {
                acf {
                full_description
                image {
                localFile {
                childImageSharp {
                    fluid(srcSetBreakpoints: 10) {
                    base64
                    tracedSVG
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                    }
                }
                }
            }
                short_description
                slug
                date
                title
                }
            }
            }
        }
    allWordpressWpDpadEvents {
        edges {
        node {
            acf {
            date
            description
            end_time
            location
            start_time
            title
            }
        }
        }
    }
    allWordpressWpMembers {
        edges {
        node {
            acf {
            member_name
            logo {
                localFile {
                childImageSharp {
                    resolutions(width: 279, height: 100) {
                    src
                    width
                    height
                    srcSet
                    }
                }
                }
            }
            }
        }
        }
    }
    }
  `