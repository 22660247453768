import React from "react"
import useFetch from "./hooks/useFetch";
import * as appConstants from '../components/app-constants';
import { Slides } from "./types/slides";
import SwiperCore, { Autoplay, EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
// import "./App.css";
// import "./Swiper.css";

SwiperCore.use([EffectCoverflow,Autoplay, Pagination]);

type TextAlign = 'start' | 'end' | 'center' | 'justify' | 'left' | 'right' | 'match-parent' | 'inherit' | 'initial' | 'unset';

export const HomeSlider = () => {
  const { loading, content } = useFetch(`${appConstants.getBannersSlides}`);
  const sortedSlidesList = content?.data?.sort((a: any, b: any) => b.id - a.id)

    return (
      <div id="slider" className="inspiro-slider slider-fullscreen dots-creative">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          centeredSlides={true}
          effect='fade'
          fadeEffect={{
              crossFade: true
          }}
          autoplay={{
            delay: 7500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          onSlideChange={undefined}
          onSwiper={(swiper) => console.log(swiper)}
        >
            {sortedSlidesList?.map((slides: Slides) => {

              let alignment: TextAlign = 'center';
              let textAlignment: TextAlign | undefined = undefined;  
              
              switch(slides.attributes.Slide_Text_Button_Alignment) {
                case 'LeftAlignment':
                  alignment = 'left';
                  textAlignment = 'start'
                  break;
                case 'RightAlignment':      
                  alignment = 'right'; 
                  textAlignment = 'end'    
                  break;   
                default:     
                  alignment = 'center'; 
                  textAlignment = 'center'
                  break;
              }
              
              const dynamicButton = slides.attributes.Slide_Button_Title
              ? (
                <div style={{ display: 'flex', justifyContent: alignment, textAlign: textAlignment }}>
                  <div className="text-light col-lg-5" style={{marginTop: 175}}>
                      <h2 className="font-weight-600"><span>{slides.attributes.Slide_Title ?? ''}</span></h2>
                      <p>{slides.attributes.Slide_Description ?? ''}</p>
                      <a href={slides.attributes.Slide_Button_URL} className="btn btn-light btn-rounded" style={{fontSize: 16, padding: 18}}>{slides.attributes.Slide_Button_Title ?? ''}</a>
                  </div>
                </div>
              )
              : (
                <div style={{ display: 'flex', justifyContent: alignment, textAlign: textAlignment }}>
                  <div className="text-light col-lg-5" style={{marginTop: 175}}>
                      <h2 className="font-weight-600"><span>Welcome to The African Nova Scotian Decade for People of African Descent Coalition (DPAD)</span></h2>
                      <p>The Coalition is an open group representing organizations that serve African Nova Scotia people, communities, interests and needs.</p>
                      <a href="/about" className="btn btn-light btn-rounded" style={{fontSize: 16, padding: 18}}>Learn More</a>
                  </div>
                </div>
              );
              
              return (
                <SwiperSlide key={slides.attributes.Slide_Title ?? 'SwipeSlide'} className="fix-slider-dpad">
                  <img src={slides?.attributes?.Slide_Image?.data?.attributes.url ?? 'https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/slide1.jpg'} alt={slides.attributes.Slide_Title ?? 'ANSDPAD Main Slide'} className="main-banner-slides" style={{ filter: 'brightness(50%)' }} />
                    <div className="container-wide overlay-text">
                        <div className="container-fullscreen">
                          {dynamicButton}
                        </div>
                    </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    );
}

export default HomeSlider
